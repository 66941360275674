<template>
  <div>
    <div class="col-12 pt-5 pb-4" style="background-color:#0437b6; border-bottom :1px solid rgba(255, 255, 255, 0.404);">
      <h3 class="text-center text-white text-uppercase ma-0 pa-0 font-weight-bold" style="text-shadow:0px 1px black;">
        {{ $t("title.Rwithdraw") }}
      </h3>
    </div>
    <div
      class="col-12 pb-4 text-center" style="background-color:#0437b6;"
    >
            <h5 class="text-white" >
              {{ $t("title.cash") }}
            </h5>
            <span
              class="v-chip v-size--default header-chip win-chip pt-1"
              style="text-shadow: 1px 2px black"
            >
                  {{ formatMoney(this.dataReportWithdraw.total_amount) }}
                  {{ userInfor.currencySymbol }}
            </span>
      
    </div>
        <v-col cols="12">
          <v-btn-toggle mandatory class="btn-group group-menu-scr">
            <v-col class="mx-0 px-2" v-for="(i,index) in buttonName" :key="index">
              <v-btn
                @click="getData(i)"
                class="btn-fansy"
              >
                <span>{{
                  $t(`button.${i}`)
                }}</span>
              </v-btn>
            </v-col>
          </v-btn-toggle>
        </v-col>
        <v-col cols="12" class="text-right ">
          <v-row >
            <v-spacer></v-spacer>
            <v-col cols="6" sm="4" md="4" lg="4">
              <v-menu
                v-model="v_startDate"
                :close-on-content-click="false"
                :nudge-right="50"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="startDate"
                    :label="$t('title.start_date')"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    class="report-label-color"
                  >
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="startDate"
                  no-title
                  @input="v_startDate = false"
                  class="report-label-color"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6" sm="4" md="4" lg="4">
              <v-menu
                v-model="v_endDate"
                :close-on-content-click="false"
                :nudge-left="120"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="endDate"
                    class="report-label-color"
                    :label="$t('title.end_date')"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  >
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="endDate"
                  @input="v_endDate = false"
                  no-title
                ></v-date-picker>
              </v-menu>
            </v-col>
            
            <v-col
              cols="12"
              sm="3"
              md="2"
              lg="2"
              class="more-right xs-more-space-submit mt-md-4 pt-0 pb-5 mr-1 px-7"
            >
              <v-btn
                class="btn-fansy pt-sm-0"
                depressed
                block
                :disabled="!startDate|| !endDate"
                :style="!startDate|| !endDate ? 'opacity:.5':'' "
                @click="getData('search')"

              >
                {{ 
                $t("button.search")
                }}
              </v-btn>
            </v-col>
          
          </v-row>
        </v-col>
  </div>
</template>


<script>
import headersReportWithdraw from "../_api/columnsReportWithdraw";
import { mapActions, mapGetters, mapMutations } from "vuex";
import clickMixin from "~mixin/MX_ShareFunctionCollection.js"
export default {
  props: ["darkmode", "status", "params"],
  data() {
    return {
      headersReportWithdraw: headersReportWithdraw,
      data: {
        startDate: null,
        endDate: null,
      },
      startDate: null,
      mixins: [clickMixin],
      endDate: null,
      v_startDate: null,
      v_endDate: null,
      buttonName:["all","today","last_day","this_week","last_week","this_month","last_month"],
    };
  },

  computed: {
    ...mapGetters("$_withdraw", {
      dataReportWithdraw: "getReportWithdraw",
    }),
    ...mapGetters("$_withdraw", ["getLoading"]),
    ...mapGetters("$_modules", {
      userInfor: "getUserInfo",
    }),
  },
  watch: {
    data: function () {
      if (this.startDate != null && this.endDate != null) {
        this.$emit("child-checkbox", this.data);
      }
    },
  },
  methods: {
    async getData(type) {
        if (type == "search") {
        this.data.startDate = this.startDate;
        this.data.endDate = this.endDate;
        }else{
          let res = this.dateBySearchType(type)
          this.data = res;
          this.startDate = res.startDate;
          this.endDate = res.endDate;
        }
        if (this.startDate != null && this.endDate != null) {
            this.$emit("child-checkbox", this.data);
        }
        else{
          this.$toastr.e("Something went wrong with select date");
        }
    },
  },

  ...mapMutations("$_withdraw", ["REPORT_WITHDRAW"]),
  ...mapActions("$_withdraw", ["fetchReportWithdraw"]),
};
</script>
